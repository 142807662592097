import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	justify-content: space-between;
	padding: 0 8px 20px;
	flex-wrap: wrap;
	column-gap: 40px;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 19px 16px;
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: flex-start;
		padding: 0 0 40px;
		font-size: var(--size-biggest);
		${({ theme }) => theme.breakpoints.up('md')} {
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
			font-size: var(--size-base);
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			border-right: none;
			padding: 0;
			justify-content: center;
		}
	}
`

const Copyright = styled('div')`
	.MuiTypography-root {
		line-height: 20px;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		.MuiTypography-root {
			line-height: 1;
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper, Copyright }
