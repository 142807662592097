import TopArea from './top-area'
import MiddleTopArea from './middle-top-area'
import MiddleBottomArea from './middle-bottom-area'
import { Wrapper } from './footer.styled'
import { string, array, object, node } from 'prop-types'
import { cx } from '@emotion/css'

const Footer = ({ className, topAreaChildComponent, newsletterProps, copyrightProps, menus = [] }) => {
	return (
		<Wrapper className={cx('Footer-root', className)}>
			<TopArea childComponent={topAreaChildComponent} />
			<MiddleTopArea menus={menus} newsletterProps={newsletterProps} />
			<MiddleBottomArea copyrightProps={copyrightProps} />
		</Wrapper>
	)
}

Footer.propTypes = {
	/**
	 * Defines a classname for root node
	 */
	className: string,
	/**
	 * Defines topArea Content
	 */
	topAreaChildComponent: node,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
	/**
	 * Defines the properties of copyright text
	 */
	copyrightProps: object,
	/**
	 * Defines the footer menus array
	 */
	menus: array,
}

export default Footer
